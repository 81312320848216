import { Radius, Spacings, Timings } from '@picsart/cascade';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

interface Props {
  image_url?: string;
}

export const useStyles = createUseStyles({
  root: {
    width: '330px',
    borderRadius: Radius.r12,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: Spacings.s24,
  },

  uploadedImageBox: (props: Props) => ({
    width: '160px',
    height: '160px',
    transition: Timings.Default,
    backgroundImage: `url(${props?.image_url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'auto !important',
  }),

  overlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  overlayBackground: {
    opacity: 0.3,
    position: 'absolute',
    left: Spacings.s0,
    top: Spacings.s0,
    bottom: Spacings.s0,
    right: Spacings.s0,
    zIndex: 0,
    transition: Timings.Default,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: Spacings.s8,
    width: '100%',
  },

  actionButton: {
    width: '100%',
  },
  bottomSheet: {
    alignItems: 'flex-end',
  },
  [MEDIA_QUERY.MOBILE]: {
    root: {
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      borderRadius: Radius.r12,
      gap: Spacings.s0,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      gap: Spacings.s8,
      width: '100%',
      height: '154px',
    },
  }
});
