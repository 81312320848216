import { View } from '@picsart/view';
import { useStyles } from './NotAllowedFreeAvatarStyles';
import { Text } from '@picsart/text';
import { Typography } from '@picsart/cascade/typography';
import { FreeAvatarGenerationPopUpContent } from 'constants/constants';
import { Image } from '@picsart/image';
import { Button, ButtonSizeLG } from '@picsart/button';
import { IReduxState } from 'types';
import { getFreeAvatarStyle } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { getUserData } from 'store/slices/auth/selectors';

const NotAllowedFreeAvatarSelector = (state: IReduxState) => ({
  freeAvatarStyle: getFreeAvatarStyle(state),
  user: getUserData(state),
});

const NotAllowedFreeAvatar = () => {
  const classes = useStyles();

  const { user } = useSelector(NotAllowedFreeAvatarSelector);

  return (
    <View className={classes.root}>
      <Text size={Typography.t6} className={classes.title}>
        {FreeAvatarGenerationPopUpContent.not_allowed.title(user?.until_free_generation || 15)}
      </Text>
      <Text size={Typography.t5} className={classes.description}>
        {FreeAvatarGenerationPopUpContent.not_allowed.description(user?.until_free_generation || 15)}
      </Text>
      <Image src={FreeAvatarGenerationPopUpContent.not_allowed.thumbnailSrc} className={classes.thumbnailImage} />
      <Button isFullWidth size={ButtonSizeLG} isDisabled>
        {FreeAvatarGenerationPopUpContent.not_allowed.buttonTitle}
      </Button>
    </View>
  );
};

export default NotAllowedFreeAvatar;
