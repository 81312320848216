import { createUseStyles } from 'react-jss';
import {
  convertEasingToCSS,
  convertRadiusToCSS,
  convertSpacingsToCSS,
  convertThemeToCSS,
  convertTimingsToCSS,
  withFontFamily,
  convertTypographyToCSS,
  EasingValues,
  RadiusValues,
  SpacingsValues,
  tabFocusStyles,
  ThemeLightValues,
  ThemeDarkValues,
  TimingValues,
  deepMergeStyles,
  TypographyValues,
  GilroyFonts,
  resetters,
  Spacings,
} from '@picsart/cascade';
import { AUTH_LOADING_BACKGROUND } from 'constants/constants';

export const useAppStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
  user_image: {
    width: '128px',
    height: '128px',
    borderRadius: '50%',
  },
  smallSizeVIew: {
    background: `url("${AUTH_LOADING_BACKGROUND}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: Spacings.s0,
    right: Spacings.s0,
    bottom: Spacings.s0,
    top: Spacings.s0,
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
});

export const useStyles = createUseStyles({
  ...deepMergeStyles(tabFocusStyles, withFontFamily(GilroyFonts), {
    '@global': {
      ...resetters,
      ':root': {
        ...convertSpacingsToCSS(SpacingsValues),
        ...convertTimingsToCSS(TimingValues),
        ...convertRadiusToCSS(RadiusValues),
        ...convertEasingToCSS(EasingValues),
        ...convertTypographyToCSS(TypographyValues),
      },
      html: {
        '&.light': {
          ...convertThemeToCSS(ThemeLightValues),
        },
        '&.dark': {
          ...convertThemeToCSS(ThemeDarkValues),
        },
      },
    },
  }),
});