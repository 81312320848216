import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { View } from '@picsart/view';
import Tabs from '@picsart/tabs/Tabs';
import Tab from '@picsart/tabs/Tab';
import TabPanel from '@picsart/tabs/TabPanel';
import { getHistoryStylePacks, getIsComingFromFreeAvatarCreation } from 'store/slices/history/selectors';
import IconComment from '@picsart/icons/IconComment';
import IconFolder from '@picsart/icons/IconFolder';
import { Spacings } from '@picsart/cascade/spacings';
import { useStyles } from './HistoryPacksStyles';
import GeneratedPack from '../GeneratedPack/GeneratedPack';
import FreeAvatarGrid from '../FreeAvatarGrid/FreeAvatarGrid';
import FreeAvatarItem from '../FreeAvatarItem/FreeAvatarItem';
import { AvatarGenerationStatus, DEVICE_SCREEN_MAXIMUM_SIZES, GENERATION_TYPES } from 'constants/constants';
import { IHistoryStylePack, IReduxState } from 'types';
import useWindowDimensions from 'hooks/useWindowDimensions';
import historySlice from 'store/slices/history';
import { dispatch } from 'store/external';
import EmptyHistory from '../EmptyHistory/EmptyHistoryPlaceholder';

const historySelector = (state: IReduxState) => ({
  historyStylePacks: getHistoryStylePacks(state),
  isComingFromFreeAvatarCreation: getIsComingFromFreeAvatarCreation(state),
});

const HistoryPacks = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [history, setHistory] = useState<IHistoryStylePack[] | null>([]);
  const [isHistoryEmpty, setIsHistoryEmpty] = useState(false);
  const [freeAvatarsHistory, setFreeAvatarsHistory] = useState<IHistoryStylePack[] | null>([]);

  const { width } = useWindowDimensions();
  const [isMobileOrTabletDevice, setIsMobileOrTabletDevice] = useState(false);

  const { historyStylePacks, isComingFromFreeAvatarCreation } = useSelector(historySelector);

  const classes = useStyles({ isFreeAvatarsHistoryEmpty: freeAvatarsHistory.length === 0 });

  useEffect(() => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setIsMobileOrTabletDevice(true);
    } else {
      setIsMobileOrTabletDevice(false);
    }
  }, [width]);

  useEffect(() => {
    if (history.length === 0) {
      setIsHistoryEmpty(true);
    } else {
      setIsHistoryEmpty(false);
    }
  }, [history])

  useEffect(() => {
    const paidAvatars = historyStylePacks.filter((item) => item.type === GENERATION_TYPES.WITH_PAYMENT);
    setHistory(paidAvatars);
    const freeAvatars = historyStylePacks.filter((item) => item.type === GENERATION_TYPES.FREE);
    setFreeAvatarsHistory(freeAvatars);
  }, [historyStylePacks]);

  const handleActiveTabChange = (tabValue) => {
    setActiveTab(tabValue);
  };

  useEffect(() => {
    if (isComingFromFreeAvatarCreation) {
      dispatch(historySlice.actions.setIsComingFromFreeAvatarCreation(false));
      setActiveTab(1);
    }
  }, [isComingFromFreeAvatarCreation]);

  return (
    <View flexDirection='column' width='100%' height="100%" justifyContent={'flex-start'}>
      <Tabs className={classes.tabs} isFixed key={`${isMobileOrTabletDevice}`} value={activeTab} onChange={handleActiveTabChange}>
        <Tab className={classes.tab} startIcon={IconFolder} key={`${isMobileOrTabletDevice}`}>
          Style sets
        </Tab>
        {freeAvatarsHistory.length > 0 && (
          <Tab className={classes.tab} startIcon={IconComment} key={`${isMobileOrTabletDevice}`}>
            Free avatars
          </Tab>
        )}
      </Tabs>
      <View marginBlockStart={Spacings.s16} width='100%' height='100%' alignItems="center">
        <TabPanel className={classes.tabpanel} value={activeTab} index={0}>
          <View flexDirection='column' width={'100%'}>
            {history.length > 0 &&
              history?.map((item: IHistoryStylePack) => {
                return <GeneratedPack key={item._id} data={item} />;
              })
            }
          </View>
          {isHistoryEmpty && (
            <View marginBlockStart={Spacings.s72} width={'100%'} justifyContent="center">
              <EmptyHistory/>
            </View>
          )}
        </TabPanel>
      </View>
      {freeAvatarsHistory.length > 0 && (
        <TabPanel value={activeTab} index={1}>
          <View flexDirection='column' width={'100%'}>
            <FreeAvatarGrid>
              {freeAvatarsHistory?.map((item: IHistoryStylePack) => {
                return <FreeAvatarItem key={`${item._id} ${item.date}`} images={item.images} created_at={item.date} status={item.status as AvatarGenerationStatus} />;
              })}
            </FreeAvatarGrid>
          </View>
        </TabPanel>
      )}
    </View>
  );
};

export default memo(HistoryPacks);
