import { Spacings } from '@picsart/cascade/spacings';
import { Timings } from '@picsart/cascade/timings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  downloadAllWrapper: {
    cursor: 'pointer',
    transition: Timings.Default,
    '&:hover': {
      transform: 'scale(.99)',
    },
  },
  packGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    gap: Spacings.s16,
    marginTop: Spacings.s16,
    width: '100%',
    height: 'auto',
  },
  [MEDIA_QUERY.TABLET]: {
    packGridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: Spacings.s16,
      marginTop: Spacings.s16,
      width: '100%',
      height: 'auto',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    packGridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: Spacings.s16,
      marginTop: Spacings.s16,
      width: '100%',
      height: 'auto',
    },
  },
});
