import { StandardHost, StandardHostHeader } from '@picsart/standardhost';
import { ReactElement } from 'react';
import { useStyles } from './ContainerStyles';
import LeftSideHeader from 'components/Header/LeftSideHeader';
import RightSideHeader from 'components/Header/RightSideHeader';
import { StandardMiddleware } from '@picsart/standardmiddleware';
import { StandardRoom, StandardSlot } from '@picsart/standardSlot';
import MobileNavigation from 'components/MobileNavigation/MobileNavigation';

interface Props {
  content: ReactElement;
}

const StandardWrapper = ({ content }: Props) => {
  const classes = useStyles();
  return (
    <>
      <StandardHost
        header={<StandardHostHeader className={classes.header} isFixed leftChildren={<LeftSideHeader />} rightChildren={<RightSideHeader />} />}
        middleWare={<StandardMiddleware slot={<StandardSlot room={<StandardRoom className={classes.root}>{content}</StandardRoom>} />} />}
      />
      <MobileNavigation />
    </>
  );
};

export default StandardWrapper;
