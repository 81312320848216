import { View } from '@picsart/view';
import { useStyles } from './EmptyHistoryPlaceholderStyles';
import IconFolder from '@picsart/icons/IconFolder';
import { Texts } from '@picsart/cascade/colors/texts';
import { Typography } from '@picsart/cascade/typography';
import { Text } from '@picsart/text';
import { Button, ButtonSizeMD, ButtonVariants } from '@picsart/button';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { GenerationFlowPagesEnum } from 'constants/constants';

const EmptyHistory = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigateToMainPage = () => {
    dispatch(generationFlowSlice.actions.setCurrentPage(GenerationFlowPagesEnum.SELECT_STYLES_AND_CATEGORIES));
    navigate('/');
  };

  return (
    <View className={classes.root}>
      <View className={classes.icon}>
        <IconFolder fill={Texts.base} />
      </View>
      <Text className={classes.title} size={Typography.t6}>
        What will you create first?
      </Text>
      <View className={classes.descriptionBox}>
        <Text className={classes.descriptionItem} size={Typography.t5}>
          Whether working solo, or as part of a team, Workspace is your
        </Text>
        <Text className={classes.descriptionItem} size={Typography.t5}>
          all-in-one toolkit for productivity and collaboration.
        </Text>
      </View>
      <Button
        className={classes.button}
        variant={ButtonVariants.Outline}
        size={ButtonSizeMD}
        onClick={navigateToMainPage}
      >
        Create avatars
      </Button>
    </View>
  );
};

export default EmptyHistory;
