import { View } from '@picsart/view';
import { useStyles } from './ConfirmationStyles';
import { Text, TypographyHorizontalAlign } from '@picsart/text';
import { Checkbox } from '@picsart/checkbox';
import { ITypography, Typography } from '@picsart/cascade/typography';
import { FontWeights } from '@picsart/cascade/fonts';
import { Link } from '@picsart/link';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { IReduxState } from 'types';
import { getIsTermsCheckedStatus } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { DEVICE_SCREEN_MAXIMUM_SIZES, TERMS_OF_USE_URL } from 'constants/constants';
import { getDiscordSDK } from 'store/slices/main/selectors';

const rulesConfirmationSelector = (state: IReduxState) => ({
  isTermsChecked: getIsTermsCheckedStatus(state),
  discordSDK: getDiscordSDK(state),
});

const RulesConfirmation = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t5);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setTextSize(Typography.t4);
    } else {
      setTextSize(Typography.t5);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  const { isTermsChecked, discordSDK } = useSelector(rulesConfirmationSelector);
  console.log("discordSdk", discordSDK);
  const handleOpenTermsPage = () => {
    discordSDK?.commands.openExternalLink({ url: TERMS_OF_USE_URL });
  };

  return (
    <View className={classes.root}>
      <Checkbox isChecked={isTermsChecked} onChange={() => dispatch(generationFlowSlice.actions.setIsTermsCheckedStatus(!isTermsChecked))} className={classes.checkbox} />
      <View className={classes.textContainer}>
        <Text weight={FontWeights.Regular} size={textSize} align={TypographyHorizontalAlign.Center}>
          I confirm that I am 18 years of age or older and have read and agree
        </Text>
        <Text weight={FontWeights.Regular} size={textSize} align={TypographyHorizontalAlign.Center}>
          to the of
          <Link onClick={handleOpenTermsPage} target='_blank' weight={FontWeights.Bold} size={textSize} className={classes.url}>
            {' '}
            Terms and Conditions{' '}
          </Link>
          AI Avatar.
        </Text>
      </View>
    </View>
  );
};

export default RulesConfirmation;
