import { View } from '@picsart/view';
import { useStyles } from './VisualContentStyles';
import Title from 'components/Title/Title';
import ShortDescription from 'components/ShortDescription/ShortDescription';
import RulesImageList from 'components/Rules/ImageList/ImageList';
import { RulesDefaultParams } from 'constants/constants';

const RulesVisualContent = () => {
  const classes = useStyles();
  return (
    <View className={classes.root}>
      <Title content={RulesDefaultParams.title} />
      <View className={classes.descriptionBlock}>
        <ShortDescription content={RulesDefaultParams.descriptions.description_1} />
        <ShortDescription content={RulesDefaultParams.descriptions.description_2} />
      </View>

      <RulesImageList imagePathList={RulesDefaultParams.images.allowed} basePath={RulesDefaultParams.images.basePath} />
      <ShortDescription content={RulesDefaultParams.descriptions.description_3} />
      <RulesImageList imagePathList={RulesDefaultParams.images.not_allowed} basePath={RulesDefaultParams.images.basePath} />
    </View>
  );
};

export default RulesVisualContent;
