import { View } from '@picsart/view';
import { useStyles } from './StepsStyles';
import { DEVICE_SCREEN_MAXIMUM_SIZES, HowItWorksDefaultParams } from 'constants/constants';
import { IHowItWorksStep } from 'types';
import Step from './Step/Step';
import { Text } from '@picsart/text';
import { Typography, ITypography } from '@picsart/cascade/typography';
import { useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Image } from '@picsart/image';

const Steps = () => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t7);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.TABLET) {
      setTextSize(Typography.t6);
    } else {
      setTextSize(Typography.t7);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.root}>
      <Image
        src={`${HowItWorksDefaultParams.creation_steps.thumbnail.baseUrl}/${HowItWorksDefaultParams.creation_steps.thumbnail.pathname}`}
        alt={'Steps thumbnail before/after'}
        className={classes.viewItem}
      />
      <View className={classes.viewItem}>
        <Text size={textSize} className={classes.steps_title}>
          {HowItWorksDefaultParams.creation_steps.title}
        </Text>
        <View className={classes.steps}>
          {HowItWorksDefaultParams.creation_steps.steps.map((step: IHowItWorksStep, key: number) => (
            <Step step={step} key={step.title} index={key + 1} />
          ))}
        </View>
      </View>
    </View>
  );
};

export default Steps;
