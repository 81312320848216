import {
  Backgrounds,
  FontWeights,
  Radius,
  Spacings,
  Texts,
} from '@picsart/cascade';
import { TypographyHorizontalAlign } from '@picsart/text';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  icon: {
    borderRadius: Radius.circle,
    background: Backgrounds.tint3,
    padding: Spacings.s12,
    marginBottom: Spacings.s16,
  },
  title: {
    fontWeight: FontWeights.SemiBold,
    textAlign: TypographyHorizontalAlign.Center,
    marginBottom: Spacings.s8,
  },
  descriptionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: Spacings.s16,
  },
  descriptionItem: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
    color: Texts.tint1,
  },
  button: {
    width: '220px',
  },
});
