import { FontWeights, Spacings } from '@picsart/cascade';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: Spacings.s48,
  },
  viewItem: { width: `calc(50% - ${Spacings.s48})`, flexDirection: 'column' },
  steps_title: {
    fontWeight: FontWeights.SemiBold,
    marginTop: Spacings.s72,
  },
  steps: {
    flexDirection: 'column',
    marginTop: Spacings.s32,
  },
  [MEDIA_QUERY.TABLET]: {
    root: { gap: Spacings.s0 },
    viewItem: { width: '50%' },
    steps_title: { marginTop: Spacings.s48 },
    steps: { marginTop: Spacings.s24 },
  },
  [MEDIA_QUERY.MOBILE]: {
    root: {
      flexDirection: 'column',
    },
    viewItem: { width: '100%' },
    steps_title: { marginTop: Spacings.s0 },
  },
});
