import { DISCORD_ACTIVITY_URL_MAPPINGS } from 'constants/constants';

export const getCDNURL = (image: any | null) => {
  let cdn: string = DISCORD_ACTIVITY_URL_MAPPINGS.AI_CDN;
  if (String(image?.url).includes('//aicdn-avatar.')) {
    cdn = DISCORD_ACTIVITY_URL_MAPPINGS.AI_CDN_AVATAR;
  } else if ('//cdn-ai-avatar-results.') {
    cdn = DISCORD_ACTIVITY_URL_MAPPINGS.AIAVATAR_CDN;
  }
  return `${cdn}/${image?.id}`;
};

export const getMediaURL = (image: any | null) => {
  return `/.proxy/api/v2/media/image/${image?.id}`;
}
