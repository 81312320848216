import { useEffect, useState } from 'react';
import BottomSheet from '@picsart/bottomsheet/BottomSheet';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { View } from '@picsart/view';
import { Popup } from '@picsart/popup';
import { useStyles } from './FreeAvatarPopUpStyles';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
}

const FreeAvatarWrapper = ({ children, isOpen, handleClose }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const classes = useStyles();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return isMobile ? (
    <BottomSheet title='' zIndex={3} customHeight={500} isOpen={isOpen} onClose={handleClose} isFullWidth isSafeAreaExist={false} hideHandleLine reverseIcons>
      <View height='100%' alignItems='flex-end' width='100%'>
        {children}
      </View>
    </BottomSheet>
  ) : (
    <Popup isShown={isOpen} onClose={handleClose} zIndex={2}>
      <View className={classes.root}>{children}</View>
    </Popup>
  );
};

export default FreeAvatarWrapper;
