import { View } from '@picsart/view';
import Text from '@picsart/text/Text';
import { FontWeights } from '@picsart/cascade/fonts';
import { Typography } from '@picsart/cascade/typography';
import { TypographyHorizontalAlign } from '@picsart/text';
import { Skeleton } from '@picsart/skeleton';
import { Backgrounds } from '@picsart/cascade/colors/backgrounds';
import Image from '@picsart/image/Image';
import { Button, ButtonSizeSM, ButtonSkinMarket } from '@picsart/button';
import { useStyles } from './GeneratedImageStyles';
import { IHistoryImage } from 'types';
import { getCDNURL } from 'utils/getCDNUrl';
import useDownload from 'hooks/useDownload';

interface Props {
  image: IHistoryImage;
  isLoading: boolean;
  isFailed: boolean;
  index: number;
  handleImageClick: (index: number) => void;
}

const GeneratedImage = ({ index, image, isLoading, isFailed, handleImageClick }: Props) => {
  const classes = useStyles();
  const { download } = useDownload();

  const handleDownload = () => {
    if (image) {
      download(image.url);
    }
  };

  return isFailed ? (
    <View width="100%" position="relative" className={classes.skeletonWrapper} backgroundColor={Backgrounds.tint1} />
  ) : isLoading ? (
    <View width="100%" position="relative" className={classes.skeletonWrapper}>
      <Skeleton width={1000} height={1000} />
    </View>
  ) : (
    <View flexDirection="column">
      <View className={classes.packGridItem} key={image?._id} flex={1}>
        <Image
          key={image?._id}
          width={'100%'}
          height={'auto'}
          className={classes.generatedImage}
          src={image?.url ? `${getCDNURL(image)}?type=webp&to=crop&r=512` : null}
          onClick={() => handleImageClick(index)}
        />
        <View
          className={classes.downloadBox}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            handleDownload();
          }}
        >
          <Text
            weight={FontWeights.SemiBold}
            size={Typography.t5}
            align={TypographyHorizontalAlign.Center}
            color={'white'}
          >
            Download
          </Text>
        </View>
      </View>
      <Button
        className={classes.downloadButton}
        size={ButtonSizeSM}
        skin={ButtonSkinMarket}
        isDisabled={isLoading}
        onClick={handleDownload}
      >
        Download
      </Button>
    </View>
  );
};

export default GeneratedImage;
