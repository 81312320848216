import { FontWeights, Spacings } from '@picsart/cascade';
import { TypographyHorizontalAlign } from '@picsart/text';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: Spacings.s16,
  },
  title: {
    fontWeight: FontWeights.SemiBold,
    textAlign: TypographyHorizontalAlign.Center,
  },
  description: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
  },
  thumbnailImage: {
    width: 'auto',
    height: '160px',
    margin: `${Spacings.s12} ${Spacings.s0}`,
  },
  thumbnailTitle: {
    fontWeight: FontWeights.Medium,
    textAlign: TypographyHorizontalAlign.Center,
  },
});
