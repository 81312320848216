import { View } from '@picsart/view';
import { useStyles } from './FreeAvatarGridStyles';

interface Props {
  children: React.ReactNode;
}

const FreeAvatarGrid = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <View className={classes.packGridContainer} width="100%">
      {children}
    </View>
  );
};

export default FreeAvatarGrid;
