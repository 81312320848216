import { Spacings } from '@picsart/cascade';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: Spacings.s6,
    marginTop: Spacings.s32,
    marginBottom: Spacings.s16,
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    width: Spacings.s24,
    height: Spacings.s24,
    '& svg, input': {
      width: Spacings.s24,
      height: Spacings.s24,
    },
  },
  url: {
    cursor: 'pointer',
  },
  [MEDIA_QUERY.MOBILE]: {
    root: { marginTop: Spacings.s16 },
  },
});
