import { Accents } from '@picsart/cascade/colors/accents';
import { Spacings } from '@picsart/cascade/spacings';
import { Timings } from '@picsart/cascade/timings';
import { createUseStyles } from 'react-jss';
import { Radius } from '@picsart/cascade/radius';
import { MEDIA_QUERY } from 'constants/constants';

type Props = {
  isZoomedIn?: boolean,
  isFreeAvatarsHistoryEmpty?: boolean,
};

export const useStyles = createUseStyles({
  freeAvatarGridItem: (props: Props = { isZoomedIn: false }) => {
    return {
      width: '100%',
      borderRadius: Radius.r16,
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
      cursor: props.isZoomedIn ? 'zoom-in' : 'default',
      transition: Timings.Default,
      '&:hover': {
        transform: 'scale(.99)',
        '& $downloadBox': {
          bottom: '0px',
        },
      },
    };
  },
  downloadBox: {
    left: 0,
    right: 0,
    height: '39px',
    bottom: '-50px',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    background: Accents.primary.base.default,
    padding: `${Spacings.s12}`,
    transition: Timings.Default,
    cursor: 'pointer',
  },
  skeletonWrapper: {
    borderRadius: Radius.r16,
    overflow: 'hidden',
    aspectRatio: '1/1',
  },
  downloadButton: {
    display: 'none',
  },
  [MEDIA_QUERY.TABLET]: {
    downloadBox: {
      display: 'none',
    },
    downloadButton: {
      display: 'flex',
      marginTop: Spacings.s8,
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    downloadBox: {
      display: 'none',
    },
    downloadButton: {
      display: 'flex',
      marginTop: Spacings.s8,
    },
  },
});
