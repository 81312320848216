import { Accents, Radius, Spacings, Texts, Timings } from '@picsart/cascade';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  category: {
    border: `1.5px solid ${Texts.tint3}`,
    width: '100%',
    transition: Timings.Default,
    padding: `${Spacings.s16} ${Spacings.s8}`,
    borderRadius: Radius.r16,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      borderColor: `${Texts.tint2}`,
      transform: 'scale(.99)',
    },
  },
  label: {
    transition: Timings.Default,
    marginLeft: Spacings.s12,
  },
  selectedCategory: {
    borderColor: `${Accents.primary.base.default}`,
    '&:hover': {
      borderColor: `${Accents.primary.base.default}`,
    },
  },
});
