import { Absolutes } from '@picsart/cascade/colors/absolutes';
import { Spacings } from '@picsart/cascade/spacings';
import { Statuses } from '@picsart/cascade/colors/statuses';
import { Texts } from '@picsart/cascade/colors/texts';
import IconAIAvatar from '@picsart/icons/IconAIAvatar';
import IconDiscoverOutline from '@picsart/icons/IconDiscoverOutline';
import IconAsk from '@picsart/icons/IconAsk';
import IconTickCircleOutline from '@picsart/icons/IconTickCircleOutline';
import IconWarningCircleOutline from '@picsart/icons/IconWarningCircleOutline';
import { Loader, LoaderSizeLG, LoaderVariants } from '@picsart/loader';
import { IHowItWorksDefaultParams, INavigationItemsType, IRulesDefaultParams } from '../types';

export const cmsURL = process.env.REACT_APP_CMS_URL;

export enum AvatarGenerationStatus {
  ACCEPTED = 'ACCEPTED',
  INIT = 'INIT',
  FAILED = 'FAILED',
  FAILED_CREDIT = 'FAILED_CREDIT',
  FAILED_IMAGE = 'FAILED_IMAGE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum GenerationFlowPagesEnum {
  SELECT_STYLES_AND_CATEGORIES = 'SELECT_STYLES_AND_CATEGORIES',
  TERMS_OF_USE_AND_IMAGE_UPLOAD = 'TERMS_OF_USE_AND_IMAGE_UPLOAD',
}

export enum FreeAvatarGenerationFlowPagesEnum {
  FREE_GENERATION_INTRO = 'FREE_GENERATION_INTRO',
  SELECT_CATEGORIES = 'SELECT_CATEGORIES',
}

export enum GenerationFlowTypesEnum {
  FREE_AVATAR_GENERATION = 'FREE_AVATAR_GENERATION',
  AVATAR_GENERATION_WITH_PAYMENT = 'AVATAR_GENERATION_WITH_PAYMENT',
}

export const DISCORD_PROXY_BASE_URL = `https://${process.env.REACT_APP_DISCORD_CLIENT_ID}.discordsays.com/.proxy`;

export enum DISCORD_ACTIVITY_URL_MAPPINGS {
  PASTATIC = 'pastatic',
  AIAVATAR_CDN = 'aiavatar-cdn/ai-avatar-results',
  AI_CDN = 'ai-cdn',
  AI_CDN_AVATAR = 'aicdn-avatar',
  CMS = 'cms',
}

export enum ImageUploadStatusEnum {
  SUCCESS = 'success',
  UPLOAD_FAILED = 'upload_failed',
  USER_NOT_FOUND = 'user_not_found',
}

export enum ImageFaceCheckStatusEnum {
  VALID = 'VALID',
  NOT_VALID = 'IMAGE_NOT_VALID',
}

export enum ImageFaceCheckStepsEnum {
  NULL = 'null',
  VALID = 'valid',
  LOADING = 'loading',
  NOT_VALID = 'not_valid',
}

export const ImageFaceCheckStepsOptions = {
  valid: {
    bgColor: Statuses.success.base.default,
    opacity: 0.3,
    description: 'Face detection successful!',
    icon: (
      <IconTickCircleOutline
        fill={Texts.positive}
        style={{
          zIndex: 1,
          width: Spacings.s32,
          height: Spacings.s32,
        }}
      />
    ),
  },
  loading: {
    bgColor: Absolutes.black.base.default,
    opacity: 0.7,
    description: 'Face detection in progress. This will only take a few seconds.',
    icon: <Loader variant={LoaderVariants.Local} zIndex={0} isWithoutBackground size={LoaderSizeLG} />,
  },
  not_valid: {
    bgColor: Statuses.error.base.default,
    opacity: 0.3,
    description: 'No face, or multiple faces, detected. Try again with a different image.',
    icon: (
      <IconWarningCircleOutline
        fill={Texts.positive}
        style={{
          zIndex: 1,
          width: Spacings.s32,
          height: Spacings.s32,
        }}
      />
    ),
  },
};

export const NavigationItems: INavigationItemsType[] = [
  {
    title: 'My avatars',
    icon: IconAIAvatar,
    url: '/my-avatars',
  },
  {
    title: 'Explore & create',
    icon: IconDiscoverOutline,
    url: '/',
  },
  {
    title: 'How it works',
    icon: IconAsk,
    url: '/how-it-works',
  },
];

export const RulesDefaultParams: IRulesDefaultParams = {
  title: 'Upload your image',
  descriptions: {
    description_1: 'Avoid extreme angles capture the selfie from a natural perspective, approximately at eye level.',
    description_2: 'Ensure that the image you upload includes only you.',
    description_3:
      'No sunglasses, no children, no nudity, no pets, no additional objects should be visible in the photo',
  },
  images: {
    basePath: '/images/rules/',
    allowed: ['allowed1.webp', 'allowed2.webp', 'allowed3.webp', 'allowed4.webp', 'allowed5.webp'],
    not_allowed: [
      'not_allowed1.webp',
      'not_allowed2.webp',
      'not_allowed3.webp',
      'not_allowed4.webp',
      'not_allowed5.webp',
    ],
  },
};

export const HowItWorksDefaultParams: IHowItWorksDefaultParams = {
  title: "Take a peek at what's possible with AI avatar maker",
  description: "Generate the best AI avatars with Picsart's state-of-the-art AI integration. Get a preview of AI avatars generated using Picsart.",
  styles: [
    {
      title: 'Cyberpunk',
      key: 'cyberpunk',
      images: {
        baseUrl: './images/how-it-works',
        pathnames: ['1.webp', '2.webp', '3.webp'],
      },
    },
    {
      title: 'Anime',
      key: 'anime',
      images: {
        baseUrl: './images/how-it-works',
        pathnames: ['1.webp', '2.webp', '3.webp'],
      },
    },
    {
      title: 'Mystic',
      key: 'mystic',
      images: {
        baseUrl: './images/how-it-works',
        pathnames: ['1.webp', '2.webp', '3.webp'],
      },
    },
    {
      title: 'Pirate',
      key: 'pirate',
      images: {
        baseUrl: './images/how-it-works',
        pathnames: ['1.webp', '2.webp', '3.webp'],
      },
    },
    {
      title: 'Elf',
      key: 'elf',
      images: {
        baseUrl: './images/how-it-works',
        pathnames: ['1.webp', '2.webp', '3.webp'],
      },
    },
  ],
  creation_steps: {
    title: 'How to create avatars',
    thumbnail: {
      baseUrl: './images/how-it-works',
      pathname: 'steps-thumbnail.webp',
    },
    steps: [
      {
        title: 'Choose your preferences',
        description: 'Select your desired style and how you would like your results represented.',
        button: {
          title: 'Create avatars',
          icon: IconAIAvatar,
          url: '/',
        },
      },
      {
        title: 'Upload your image',
        description: 'Select your best image.',
      },
      {
        title: 'Download and enjoy',
        description: 'Download your avatars and enjoy.',
      },
    ],
  },
};

export const FREE_AVATAR_POP_UP_CLOSE_BUTTON_WIDTH = 40;

export const FreeAvatarGenerationPopUpContent = {
  intro: {
    title: 'Generate 1 free avatar every 15 days!',
    description: 'The avatar will be based on the most trendy style at the moment:',
    buttonTitle: 'Create the avatar',
  },
  categories: {
    title: 'How would you like your avatar results presented?',
    buttonTitle: 'Continue',
  },
  not_allowed: {
    title: (days: number) => `${days} days left until next free avatar!`,
    description: (days: number) =>
      `Wondering what it will be? Come back in ${days} days to enjoy your next free mystery avatar.`,
    thumbnailSrc: '/images/calendar.webp',
    buttonTitle: 'Create the avatar',
  },
};

export const DEVICE_SCREEN_MAXIMUM_SIZES = {
  MOBILE: 770,
  TABLET: 1100,
  LAPTOP: 1365,
};

export const MEDIA_QUERY = {
  MOBILE: `@media (max-width: ${DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE}px)`,
  TABLET: `@media (max-width: ${DEVICE_SCREEN_MAXIMUM_SIZES.TABLET}px)`,
  LAPTOP: `@media (max-width: ${DEVICE_SCREEN_MAXIMUM_SIZES.LAPTOP}px)`,
};

export const USER_AVATAR_PLACEHOLDER = 'cdn190/232804661007900.png';

export enum GENERATION_TYPES {
  FREE = '1',
  WITH_PAYMENT = '0',
}

export const TERMS_OF_USE_URL = 'https://picsart.com/avatarterms/';

export const AUTH_LOADING_BACKGROUND = '/images/auth_background.webp';
