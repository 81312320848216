import { TypographyHorizontalAlign } from '@picsart/text';
import { Radius } from '@picsart/cascade/radius';
import { Spacings } from '@picsart/cascade/spacings';
import { Backgrounds } from '@picsart/cascade/colors/backgrounds';
import { Statuses } from '@picsart/cascade/colors/statuses';
import { FontWeights } from '@picsart/cascade/fonts';
import { Typography } from '@picsart/cascade/typography';
import Text from '@picsart/text/Text';
import { Texts } from '@picsart/cascade/colors/texts';
import { View } from '@picsart/view';
import IconWarningCircleOutline from '@picsart/icons/IconWarningCircleOutline';
import { useStyles } from './FailedGenerationMessageStyles';
import { useEffect, useState } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DEVICE_SCREEN_MAXIMUM_SIZES } from 'constants/constants';

interface Props {
  title: string;
  description: string;
}

const FailedGenerationMessage = ({ title, description }: Props) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const classes = useStyles();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, [width]);

  return (
    <View
      className={classes.warningWrapper}
      borderRadius={Radius.r16}
      marginBlockStart={Spacings.s16}
      position="absolute"
      flexDirection="column"
      zIndex={1}
      backgroundColor={Backgrounds.panel}
      justifyContent="center"
      alignItems="center"
    >
      <View width="100%" maxWidth={320} justifyContent="center" alignItems="center" flexDirection="column">
        <View
          backgroundColor="white"
          borderRadius={Radius.circle}
          height={56}
          width={56}
          justifyContent="center"
          alignItems="center"
        >
          <IconWarningCircleOutline fill={Statuses.error.base.default} height={24} width={24} />
        </View>
        <View marginBlockStart={Spacings.s16} width="100%" justifyContent="center">
          <Text
            color={Statuses.error.base.default}
            weight={FontWeights.SemiBold}
            size={isMobileDevice ? Typography.t4 : Typography.t5}
          >
            {title}
          </Text>
        </View>
        <View
          marginBlockStart={Spacings.s8}
          width="100%"
          justifyContent="center"
        >
          <Text
            size={isMobileDevice ? Typography.t4 : Typography.t3}
            weight={FontWeights.Medium}
            color={Texts.tint1}
            align={TypographyHorizontalAlign.Center}
          >
            {description}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default FailedGenerationMessage;
