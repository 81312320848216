import { Spacings } from '@picsart/cascade';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  categories: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: Spacings.s24,
    width: '100%',
    marginTop: Spacings.s24,
  },
  [MEDIA_QUERY.TABLET]: {
    categories: {
      gap: Spacings.s16,
      width: '100%',
      marginTop: Spacings.s16,
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    categories: {
      flexDirection: 'column',
    },
  },
});
