import { Radius } from '@picsart/cascade/radius';
import { Spacings } from '@picsart/cascade/spacings';
import { Timings } from '@picsart/cascade/timings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    alignItems: 'center',
    flexDirection: 'column',
    gap: Spacings.s24,
    width: '100%',
  },
  imageList: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: Spacings.s16,
    width: '100%',
    display: 'flex',
  },
  imageView: {
    width: `calc(33% - ${Spacings.s16})`,
    borderRadius: Radius.r24,
    height: '260px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '100%',
    transition: Timings.Default,
    '&:hover': {
      backgroundSize: '105%',
    },
  },
  [MEDIA_QUERY.TABLET]: {
    imageList: {
      gap: Spacings.s10,
    },
    imageView: {
      width: `calc(33% - ${Spacings.s10})`,
      borderRadius: Radius.r20,
      height: '160px',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    imageList: {
      maxWidth: '100%',
      overflowX: 'scroll',
      justifyContent: 'flex-start',
    },
    imageView: {
      minWidth: '220px',
      height: '220px',
    },
  },
});
