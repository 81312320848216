import { Accents } from '@picsart/cascade/colors';
import { FontWeights } from '@picsart/cascade/fonts';
import { Radius } from '@picsart/cascade/radius';
import { Spacings } from '@picsart/cascade/spacings';
import { Timings } from '@picsart/cascade/timings';
import { MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: Spacings.s48,
  },
  styles: {
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    gap: Spacings.s32,
    marginTop: Spacings.s24,
  },
  style: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  selectedStyle: {
    position: 'absolute',
    top: Spacings.s0,
    bottom: Spacings.s0,
    left: Spacings.s0,
    right: Spacings.s0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0)',
    overflow: 'hidden',
    borderRadius: Radius.circle,
    transition: Timings.Default,
  },
  selectedStyleActive: {
    borderRadius: Radius.r0,
    transform: 'scale(1)',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: Accents.secondary.base.default,
    opacity: '.2',
  },
  overlayIcon: {
    borderRadius: Radius.circle,
    background: Accents.secondary.base.default,
    padding: Spacings.s10,
  },

  title: {
    fontWeight: FontWeights.SemiBold,
    marginTop: Spacings.s8,
  },
  thumbnail: {
    width: '100%',
    height: 'auto',
    aspectRatio: '1/1',
    position: 'relative',
    transition: Timings.Default,
    '&:hover': {
      transform: 'scale(.98)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
  [MEDIA_QUERY.TABLET]: {
    root: {
      marginTop: Spacings.s32,
    },
    styles: {
      gap: Spacings.s16,
      marginTop: Spacings.s16,
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    root: {
      marginTop: Spacings.s24,
    },
    styles: {
      gap: Spacings.s8,
      marginTop: Spacings.s12,
    },
    overlayIcon: {
      padding: Spacings.s6,
    },
    title: {
      marginTop: Spacings.s6,
    },
  },
});
