import { Accents } from '@picsart/cascade/colors/accents';
import { Texts } from '@picsart/cascade/colors/texts';
import { Timings } from '@picsart/cascade/timings';
import { Spacings } from '@picsart/cascade/spacings';
import { FontWeights } from '@picsart/cascade/fonts';
import { FREE_AVATAR_POP_UP_CLOSE_BUTTON_WIDTH, MEDIA_QUERY } from 'constants/constants';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  leftSide: { alignItems: 'center', gap: Spacings.s48 },
  navigation: { alignItems: 'center', gap: Spacings.s24 },
  navigationItem: {
    transition: Timings.Default,
    fontWeight: FontWeights.SemiBold,
    color: Texts.tint1,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(0.98)',
    },
  },
  activeNavigationItem: { color: Accents.secondary.base.default },
  mobileLogo: {
    width: 28,
    height: 28,
  },
  menuIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  leftSideMobileOrTablet: {
    display: 'none',
  },
  leftSideDesktopOrLaptop: {
    display: 'flex',
  },

  rightSide: { alignItems: 'center', gap: Spacings.s24, flexDirection: 'row-reverse' },
  actions: { alignItems: 'center', gap: Spacings.s16 },
  button: { width: '160px' },

  userAvatar: {
    width: 48,
    height: 48,
  },
  createAvatarButton: {
    display: 'flex',
  },

  [MEDIA_QUERY.TABLET]: {
    leftSide: { gap: Spacings.s10 },
    rightSide: { gap: Spacings.s0 },
    actions: { gap: Spacings.s8 },
    userAvatar: {
      display: 'none !important',
    },
    leftSideMobileOrTablet: {
      display: 'flex',
    },
    leftSideDesktopOrLaptop: {
      display: 'none',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    createAvatarButton: {
      display: 'none',
    },
  },
});
