import React, { useEffect } from 'react';
import { FreeAvatarGenerationFlowPagesEnum } from 'constants/constants';
import { IReduxState } from 'types';
import { getFreeAvatarGenerationCurrentPage, getFreeAvatarStyle } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import FreeAvatarCategorySelection from './FreeAvatarCategorySelection/FreeAvatarCategorySelection';
import FreeAvatarStyle from './FreeeAvatarStyle/FreeAvatarStyle';
import { fetchFreeStyle } from 'api/cms/getFreeStyle';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import { getUserData } from 'store/slices/auth/selectors';
import NotAllowedFreeAvatar from './NotAllowedFreeAvatar/NotAllowedFreeAvatar';
import FreeAvatarWrapper from './FreeAvatarWrapper';

interface Props {
  isPopUpOpen: boolean;
  handleClose: () => void;
}

const FreeAvatarPopUpSelector = (state: IReduxState) => ({
  freeAvatarGenerationCurrentPage: getFreeAvatarGenerationCurrentPage(state),
  freeAvatarStyle: getFreeAvatarStyle(state),
  user: getUserData(state),
});

const FreeAvatarPopUp = ({ isPopUpOpen, handleClose }: Props) => {
  const { freeAvatarGenerationCurrentPage, freeAvatarStyle, user } = useSelector(FreeAvatarPopUpSelector);

  const getFreeAvatarStyle = async () => {
    const fetchedFreeStyle = await fetchFreeStyle();

    if (fetchedFreeStyle.status === 200) {
      dispatch(generationFlowSlice.actions.setFreeAvatarStyle(fetchedFreeStyle.data.data[0]));
    } else {
      dispatch(generationFlowSlice.actions.setStyles(null));
    }
  };

  useEffect(() => {
    if (isPopUpOpen && !freeAvatarStyle) {
      getFreeAvatarStyle();
    }
    // eslint-disable-next-line
  }, [isPopUpOpen]);

  const getCurrentPageContent = () => {
    switch (freeAvatarGenerationCurrentPage) {
      case FreeAvatarGenerationFlowPagesEnum.FREE_GENERATION_INTRO:
        return <FreeAvatarStyle />;
      case FreeAvatarGenerationFlowPagesEnum.SELECT_CATEGORIES:
        return <FreeAvatarCategorySelection handleClosePopUp={handleClose} />;
    }
  };

  return (
    <FreeAvatarWrapper isOpen={isPopUpOpen} handleClose={handleClose}>
      {freeAvatarStyle && user?.until_free_generation === 0 ? getCurrentPageContent() : <NotAllowedFreeAvatar />}
    </FreeAvatarWrapper>
  );
};

export default FreeAvatarPopUp;
