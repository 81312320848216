import { Spacings } from '@picsart/cascade';
import { createUseStyles } from 'react-jss';
import { Controls } from '@picsart/cascade';
import { MEDIA_QUERY } from 'constants/constants';

export const useStyles = createUseStyles({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: Spacings.s8,
    borderRadius: Spacings.s32,
    width: 'fit-content',
    border: `${Spacings.s2} solid ${Controls.border.active}`,
  },

  [MEDIA_QUERY.MOBILE]: {
    root: {
      maxWidth: '100%',
      overflowX: 'scroll',
      justifyContent: 'flex-start',
    },
  },
});
