import { IStyleAttributes } from 'types';
import { useStyles } from './StylesSelectionStyles';
import { View } from '@picsart/view';
import { Text } from '@picsart/text';
import { ImageFrame } from '@picsart/imageframe';
import { Image } from '@picsart/image';
import IconTickLarge from '@picsart/icons/IconTickLarge';
import { Texts } from '@picsart/cascade/colors/texts';
import { DEVICE_SCREEN_MAXIMUM_SIZES, DISCORD_ACTIVITY_URL_MAPPINGS } from 'constants/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { ITypography, Typography } from '@picsart/cascade/typography';

interface Props {
  style: IStyleAttributes;
  isSelected: boolean;
  handleSelect: () => void;
}

const Style = ({ style, isSelected, handleSelect }: Props) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [textSize, setTextSize] = useState<ITypography>(Typography.t4);

  const getCurrentStyles = () => {
    if (width <= DEVICE_SCREEN_MAXIMUM_SIZES.MOBILE) {
      setTextSize(Typography.t3);
    } else {
      setTextSize(Typography.t4);
    }
  };

  useEffect(() => {
    getCurrentStyles();
    // eslint-disable-next-line
  }, [width]);

  return (
    <View className={classes.style}>
      <ImageFrame isActive={isSelected} className={classes.thumbnail} onClick={handleSelect}>
        <Image className={classes.image} src={`/${DISCORD_ACTIVITY_URL_MAPPINGS.CMS}/${style.image.data.attributes.formats.thumbnail.url}`} alt={style.title} />
        <View className={`${classes.selectedStyle} ${isSelected && classes.selectedStyleActive}`}>
          <View className={classes.overlay} />
          <View className={classes.overlayIcon}>
            <IconTickLarge fill={Texts.positive} />
          </View>
        </View>
      </ImageFrame>

      <Text size={textSize} className={classes.title}>
        {style.title}
      </Text>
    </View>
  );
};

export default Style;
