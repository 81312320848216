import { useState, useEffect } from 'react';
import { getCDNURL } from 'utils/getCDNUrl';
import { Radius } from '@picsart/cascade/radius';
import { Statuses } from '@picsart/cascade/colors/statuses';
import IconChevronLeftSmall from '@picsart/icons/IconChevronLeftSmall';
import IconChevronRightSmall from '@picsart/icons/IconChevronRightSmall';
import { Button, ButtonSizeMD, ButtonSkinTertiary } from '@picsart/button';
import { Image } from '@picsart/image';
import Overlay from '@picsart/overlay/Overlay';
import { View } from '@picsart/view';
import { IHistoryImage } from 'types';
import { useStyles } from './ResultPreviewStyles';
import { Spacings } from '@picsart/cascade/spacings';
import useDownload from 'hooks/useDownload';

interface Props {
  images: IHistoryImage[];
  isOpen: boolean;
  choosenImageIndex: number;
  onClose: () => void;
}

const ResultPreview = ({ images, isOpen, onClose, choosenImageIndex }: Props) => {
  const [currentImage, setCurrentImage] = useState(choosenImageIndex);

  const classes = useStyles();
  const { download } = useDownload();

  const handleDownload = () => {
    download(images[currentImage].url);
  };

  const handlePrevious = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(prev => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handlePrevious();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    //eslint-disable-next-line
  }, [currentImage]);

  return (
    <>
      {isOpen && (
        <Overlay zIndex={2} closeCallback={onClose}>
          <View
            className={classes.overlayContentWrapper}
            key={currentImage}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <View flexDirection="row" alignItems="center" position="relative">
              {images.length > 1 && (
                <View
                  className={classes.leftChevronWrapper}
                  height={48}
                  width={48}
                  borderRadius={Radius.circle}
                  backgroundColor="white"
                  justifyContent="center"
                  position="absolute"
                  alignItems="center"
                  marginInlineEnd={Spacings.s24}
                  onClick={handlePrevious}
                >
                  <IconChevronLeftSmall fill={Statuses.positive.text.default} />
                </View>
              )}
              <View flexDirection={'column'}>
                <Image
                  className={classes.previewImage}
                  src={`${getCDNURL(images[currentImage])}?type=webp&to=crop&r=512`}
                />
                <View marginBlockStart={Spacings.s24} gap={Spacings.s8} width={'100%'} justifyContent="center">
                  <View width={'100%'}>
                    <Button size={ButtonSizeMD} skin={ButtonSkinTertiary} isFullWidth onClick={handleDownload}>
                      Download
                    </Button>
                  </View>
                </View>
              </View>
              {images.length > 1 && (
                <View
                  className={classes.rightChevronWrapper}
                  height={48}
                  width={48}
                  borderRadius={Radius.circle}
                  backgroundColor="white"
                  justifyContent="center"
                  position="absolute"
                  alignItems="center"
                  marginInlineStart={Spacings.s24}
                  onClick={handleNext}
                >
                  <IconChevronRightSmall fill={Statuses.positive.text.default} />
                </View>
              )}
            </View>
          </View>
        </Overlay>
      )}
    </>
  );
};

export default ResultPreview;
