import { Spacings } from '@picsart/cascade/spacings';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: Spacings.s0,
    top: Spacings.s0,
    height: Spacings.s32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
