import { View } from '@picsart/view';
import { useStyles } from './GenderSelectionStyles';
import Title from 'components/Title/Title';
import { ICategoryType, IReduxState } from 'types';
import { getCategories, getSelectedCategory } from 'store/slices/generationFlow/selectors';
import { useSelector } from 'react-redux';
import { dispatch } from 'store/external';
import generationFlowSlice from 'store/slices/generationFlow';
import CategoryBox from 'components/CategoryBox/CategoryBox';

const genderSelectionSelector = (state: IReduxState) => ({
  categories: getCategories(state),
  selectedCategory: getSelectedCategory(state),
});

const GenderSelection = () => {
  const classes = useStyles();

  const { categories, selectedCategory } = useSelector(genderSelectionSelector);

  const isCategorySelected = (category: ICategoryType) => {
    return selectedCategory === category.attributes.key;
  };

  const handleSelectCategory = (category: ICategoryType) => {
    dispatch(generationFlowSlice.actions.setSelectedCategory(category.attributes.key));
    dispatch(generationFlowSlice.actions.setSelectedStyles(null));
  };

  return (
    <View className={classes.root}>
      <Title content='1. How would you like your avatar results presented.' />
      <View className={classes.categories}>
        {categories &&
          categories.map((category: ICategoryType) => (
            <CategoryBox category={category} key={category.id} isCategorySelected={isCategorySelected(category)} handleCategorySelect={() => handleSelectCategory(category)} />
          ))}
      </View>
    </View>
  );
};

export default GenderSelection;
